@font-face {
  font-family: kokila;
  src: url(./fonts/kokila.ttf);
}
@font-face {
  font-family: kokila;
  src: url(./fonts/kokilab.ttf);
  font-weight: bold;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  margin: 0;
  font-family: 'kokila';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #e6eaff;
  background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

h1 {
  font-size: 32px;
  text-transform: uppercase;
}
p {
  font-size: 20px;
}

button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: #e6eaff;
  padding: 0;
  font-family: 'kokila';
}

input[type=submit] {
  font-family: 'kokila';
}

a {
  color: #ee2b7b;
  text-decoration: underline;
  cursor: pointer;
  font-size: 20px;
}

input[type=text], input[type=password], input[type=email], input[type=date], .StripeElement {
  color: white;
  background-color: rgb(21, 23, 31);
  border: none;
  padding: 17px 20px 13px 20px;
  font-size: 24px;
  margin: 1px;
  border-radius: 3px;
  font-family: 'kokila';
  transition: border 200ms ease-in-out, margin 200ms ease-in-out;
  -webkit-appearance: none;
}
input::placeholder {
  color: #aab7c4;
}
input[type=text].error, input[type=password].error, input[type=email].error, input[type=date].error {
  border-bottom: 8px solid #D94A3B;
  margin-bottom: 5px;
}
.input-container {
  position: relative;
  width: 100%;
}
.input-header {
  position: absolute;
  top: 8px; left: 20px; right: 0;
  color: rgb(85, 95, 124);
}

@media screen and (max-width: 600px) {
  input[type=text], input[type=password], input[type=email], input[type=date] {
    padding: 15px 20px;
    padding: 17px 20px 12px 20px;
    font-size: 20px;
  }
  .input-header {
    font-size: 12px;
  }
}


.fileContainer {
    overflow: hidden;
    position: relative;
    padding: 10px;
}

.fileContainer [type=file] {
    cursor: pointer;
    display: block;
    font-size: 999px;
    filter: alpha(opacity=0);
    min-height: 100%;
    min-width: 100%;
    opacity: 0;
    position: absolute;
    right: 0;
    /* text-align: right; */
    top: 0;
}