.profile-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
}
.profile-container h1 {
  font-size: 45px;
  padding: 20px;
  text-transform: none;
}

.profile-container .btn-underline {
  position: absolute;
  top: 60px; 
}
.profile-container .btn-underline.account {
  right: 15px;
}
.profile-container .btn-underline.shop {
  left: 15px;
}


.profile-container .edit-avatar {
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  font-size: 40px;
  font-weight: bold;
  color: #11CF80;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 400ms ease-in-out;
  opacity: 0;
  cursor: pointer;
}
.profile-container .edit-avatar:hover {
  opacity: 1;
}


.profile-votes-container {
  display: flex;
  align-items: center;
  padding: 15px;
}
.profile-votes-container p {
  font-size: 46px;
  margin-bottom: -11px;
}
.profile-votes-container img {
  height: 40px;
}


@media screen and (max-width: 800px) {
  .profile-container h1 {
    font-size: 35px;
    padding: 20px;
  }
  .profile-votes-container p {
    font-size: 34px;
    margin-bottom: -8px;
  }
  .profile-votes-container img {
    height: 30px;
  }
}

@media screen and (max-width: 600px) {
  .profile-votes-container p {
    font-size: 28px;
    margin-bottom: -7px;
  }
  .profile-votes-container img {
    height: 25px;
  }
}