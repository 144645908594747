.nav-container {
  position: fixed;
  top: 0; left: 0; right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  background-color: black;
  border-bottom: 1px solid rgba(169, 184, 255,0.15);
  z-index: 100;
}
.nav-spacer {
  height: 50px;
}

.nav-container .left, .nav-container .right {
  display: flex;
  flex: 1;
  padding: 5px 10px 0 10px;
}
.nav-container .right {
  justify-content: flex-end;
}
.nav-container .right button {
  margin-left: 5px;
}
.nav-container .left button {
  margin-right: 10px;
}
.nav-container img {
  height: 50px;
  width: auto;
}

@media screen and (min-width: 650px) {
  .nav-container {
    /* left: 220px; */
  }
  .nav-container .right button {
    margin: 0 10px;
  }
}