.btn {
  border: 1px solid #ee2b7b;
  border-radius: 50px;
  padding: 8px 15px 5px 15px;
  background-color: black;
  transition: background-color 400ms ease-in-out;
  box-shadow: 0 0 10px 0 #ee2b7b;
}
.btn:hover, .btn:focus , .btn:active {
  background-color: #ee2b7b;
}
.btn.large {
  padding: 10px 25px 5px 25px;
  font-size: 28px;
}
.btn.full-width {
  width: 100%;
}
.btn.xlarge {
  padding: 20px 50px 10px 50px;
  font-size: 40px;
}
.btn.close {
  font-size: 22px;
  width: 45px;
  height: 45px;
  position: fixed;
  top: 15px; right: 15px;
  z-index: 1001;
}
.btn-underline {
  color: #ee2b7b;
  text-decoration: underline;
  font-size: 24px;
}

@media screen and (max-width: 600px) {
  .btn {
    font-size: 14px;
  }
  .btn.large {
    padding: 8px 25px 5px 25px;
    font-size: 20px;
  }
  .btn.xlarge {
    padding: 15px 30px 10px 30px;
    font-size: 30px;
  }
  .btn-underline {
    font-size: 20px;
  }
}
@media screen and (max-width: 500px) {
  .btn.xlarge {
    padding: 15px 25px 10px 25px;
    font-size: 22px;
  }
}




.modal {
  position: fixed;
  top: 0; left: 0; right: 0; bottom: 0;
  background-color: rgba(0, 0, 0, 0.92);
  z-index: 1000;
  /* overflow-y: auto; */
  transition: opacity 500ms ease-in-out, transform 500ms ease-in-out;
  -webkit-overflow-scrolling: touch;
}


.row {
  display: flex;
}
.half-row {
  flex: 1;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-text {
  text-align: center;
}



.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}
.loader-container.fullscreen {
  position: fixed;
  top: 0; left: 0; right: 0; bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
}


.voted-overlay {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ee2b7b;
  width: 100%;
  height: 50px;
  border-radius: 0 0 10px 10px;
  transition: transform 200ms linear;
  font-size: 20px;
}

.avatar {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  box-shadow:0 0 7px 5px #ee2b7b;
  background-color: black;
  overflow: hidden;
  position: relative;
}
.avatar img {
  height: 100%;
  width: 100%;
}
.avatar.small {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow:0 0 4px 2px #ee2b7b;
}

@media screen and (max-width: 800px) {
  .avatar {
    width: 150px;
    height: 150px;
  }
}


.profile-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid rgba(169, 184, 255,0.15);
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  transition: background-color 100ms ease-in-out;
}
.profile-row:hover {
  background-color: rgba(15, 16, 22, 0.5);
}

.profile-row-details {
  display: flex;
  align-items: center;
}
.profile-row-details p {
  font-size: 26px;
  margin-left: 15px;
  padding-top: 3px;
  text-transform: uppercase;
}
.profile-row .vote-count-container {
  display: flex;
}
.profile-row .vote-count-container p {
  font-size: 18px;
}
.profile-row .vote-count-container img {
  height: 18px;
}
@media screen and (max-width: 500px) {
  .profile-row-details p {
    font-size: 18px;
    padding-top: 2px;
  }
  .profile-row .vote-count-container p {
    font-size: 14px;
  }
  .profile-row .vote-count-container img {
    height: 14px;
  }
}



.no-results {
  text-align: center;
  font-size: 30px;
  color: rgb(55, 65, 75);
  margin: 25px;
  text-transform: uppercase;
}

.pink-text {
  color: #ee2b7b;
}
.red-text {
  color: #D94A3B;
}
.sub-text {
  color: rgb(89, 98, 107);
  margin: 15px;
  font-size: 22px;
}




.gallery-container {
  /* display: grid; */
  /* height: 100vh; */
  width: 100%;
  display: flex;
  overflow-x: hidden;
}
.gallery-container .btn {
  margin: 0 !important;
}
.gallery-container .image-btn {
  overflow: hidden;
  margin-top: 2px;
}
.gallery-container .image-btn img {
  transition: transform 400ms ease-in-out;
}
.gallery-container .image-btn img:hover {
  transform: scale(1.03);
}
.gallery-container .award-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.gallery-container .award-container img {
  width: 35px;
  height: auto;
  margin-right: 5px;
}



.image-header-container {
  padding: 5px;
}
.image-footer-container {
  display: flex;
  text-align: center;
  padding: 10px;
}
.image-footer-container p {
  font-size: 26px;
  margin-bottom: -6px;
}

.lovit-button {
  height: 100px;
  width: 100px;
  border: 2px solid #ee2b7b;
  border-radius: 50%;
  transition: background-color 300ms ease-in-out;
}
.lovit-button:hover, .lovit-button:focus {
  background-color: rgba(238, 43, 123, 0.5);
}
.lovit-button img {
  width: 100%;
}
@media screen and (max-width: 800px) {
  .lovit-button {
    width: 70px;
    height: 70px;
  }
}


.votes-container {
  display: flex;
  align-items: center;
  margin-left: 15px;
  padding-left: 15px;
  border-left: 2px solid #ee2b7b;
}
.votes-container img {
  height: 24px;
}



.countdown-container {
  display: flex;
  margin: 15px;
}

.countdown-container div {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ee2b7b;
  border-radius: 40px;
  margin: 10px;
  font-size: 35px;
  width: 75px;
  height: 75px;
  box-shadow:   1px 1px 0 0 #750534,
                2px 2px 0 0 #750534;
  box-sizing: border-box;
  padding-top: 5px;
}
.countdown-container div span {
  color: rgba(0, 0, 0, 0.4);
  font-size: 25px;
  font-weight: bold;
  margin-bottom: -6px;
}

@media screen and (max-width: 700px) {
  .countdown-container div {
    width: 60px;
    height: 60px;
    font-size: 25px;
    padding-top: 5px;
  }
  .countdown-container div span {
    font-size: 20px;
    margin-bottom: -3px;
  }
}




.stylized-display-text {
  font-size: 100px;
  color: #ee2b7b;
  /* text-shadow:  0px 0px 5px #ee2b7b, 0px 0px 10px #ee2b7b; */
  text-shadow:  1px 1px 0 black,
                2px 2px 0 black,
                3px 3px 0 black,
                4px 4px 0 #750534,
                5px 5px 0 #750534;
  padding: 0 20px;
  width: 100%;
  text-align: center;
  border-radius: 10px;
  box-sizing: border-box;
  letter-spacing: 10px;
}

@media screen and (max-width: 900px) {
  .stylized-display-text {
    font-size: 75px;
  }
}







.tabs-container {
  display: flex;
  justify-content: space-between;
  max-width: 700px;
  margin: 0 auto;
  border-bottom: 1px solid rgba(169, 184, 255,0.15);
}
.tab {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  font-size: 20px;
  padding: 15px;
  cursor: pointer;
}
.tab img {
  width: 40px;
}
.tab.active {
  border-bottom: 4px solid #ee2b7b;
}


@media screen and (max-width: 800px) {
  .tab {
    font-size: 16px;
  }
  .tab img {
    width: 30px;
  }
}







.numbered-list-item-container {
  display: flex;
  padding: 15px;
  width: 100%;
  max-width: 700px;
  box-sizing: border-box;
}
.numbered-list-item-container .number {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #ee2b7b;
  background-color: rgb(32, 40, 58);
  width: 30px;
  height: 30px;
  border-radius: 20px;
  margin-right: 15px;
  padding-top: 5px;
  box-sizing: border-box;
}
.numbered-list-item-container p {
  text-align: left !important;
}






.cropper-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  top: 0; left: 0; right: 0; bottom: 0;
  background-color: black;
  z-index: 1000;
}
.cropper-container button {
  position: relative;
  z-index: 1001;
  margin: 15px;
  max-width: 700px;
}




.paywall-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 700px;
  text-align: center;
  margin: 0 auto;
  padding: 40px;
  box-sizing: border-box;
}
.paywall-container h1:nth-child(1) {
  text-transform: none;
}
.paywall-container h1:nth-child(2) {
  margin: 25px 0;
}


.checkout-form {
  padding-top: 60px;
  max-width: 700px;
  margin: 0 auto;
}
.checkout-form img {
  width: 70%;
  max-width: 700px;
}
.checkout-form img.stripe-logo {
  width: 40px;
  margin-left: 5px;
  margin-top: -2px;
}
.checkout-form .StripeElement {
  margin: 10px 25px 10px 25px;
}
.checkout-form .btn {
  margin-top: 20px;
}
.checkout-form .thanks-text {
  margin-top: 50px;
}



.restricted-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 40px;
}
.restricted-container .btn {
  margin-top: 20px;
}
.restricted-container img {
  width: 50vw;
  max-width: 400px;
  height: 37.647vw;
  max-height: 301.17px;
  object-fit: contain;
}
@media screen and (max-width: 600px) {
  .restricted-container h1 {
    font-size: 20px;
  }
}




.awards-container {
  display: flex;
}

.awards-container .award {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ee2b7b;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  box-shadow:   1px 1px 0 0 #750534,
                2px 2px 0 0 #750534;
  margin: 5px;
  margin-bottom: 10px;
}

.awards-container img {
  width: auto;
  height: 30px;
}
.awards-container .award p {
  font-size: 16px;
  color: #270212;
}

@media screen and (max-width: 800px) {
  .awards-container .award {
    width: 60px;
    height: 60px;
    margin: 5px;
  }
  .awards-container img {
    height: 25px;
  }
  .awards-container .award p {
    font-size: 13px;
  }
}


.shop-button {
  border: 1px solid #ee2b7b;
  border-radius: 65px;
  padding: 15px;
  box-shadow: 0 0 10px 0 #ee2b7b;
}
.shop-button img {
  width: 50px;
  height: 50px;
}
.shop-button.large img {
  width: 100px;
  height: 100px;
}

@media screen and (max-width: 800px) {
  .shop-button img {
    width: 25px;
    height: 25px;
  }
  .shop-button {
    padding: 10px;
  }
}