.search-container {
  display: flex;
  justify-content: center;
  overflow-x: hidden;
}

.search-list {
  flex: 1.5;
  height: calc(100vh - 50px);
  overflow-y: auto;
  box-shadow: 0px 0px 20px 0 black;
  /* width: 100%; */
  /* max-width: 700px; */
}

.search-container input {
  flex: 1;
  box-sizing: border-box;
  width: 100%;
  height: calc(100vh - 50px);
  text-align: center;
  font-size: 50px;
  font-weight: bold;
  /* background-color: black; */
  /* border-right: 1px solid #ee2b7b; */
  color: #ee2b7b;
}


@media screen and (max-width: 1000px) {
  .search-container {
    flex-direction: column;
  }
  .search-list {
    height: auto;
    box-shadow: none;
  }
  .search-container input {
    height: auto;
    height: 100px;
    font-size: 30px;
    padding: 25px
  }
}