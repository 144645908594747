.leaderboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
  justify-self: flex-start;
}

.leaderboard-container .wrapper {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding-bottom: 81px;
}

.leaderboard-container > h1 {
  text-align: center;
  font-size: 60px;
  margin: 25px;
  max-width: 700px;
  text-align: center;
}

.leaderboard-container .tabs-container {
  /* display: flex;
  justify-content: space-between; */
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  border: none;
}
.leaderboard-container .tabs-container .underline {
  border-bottom: 1px solid rgba(169, 184, 255,0.15);
  width: 100%;
}
.leaderboard-container .tab {
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  font-size: 20px;
  /* padding: 15px; */
  cursor: pointer;
  /* width: calc(100% / 5); */
}
.leaderboard-container .tab img {
  width: 40px;
}
.leaderboard-container .tab.active {
  border-bottom: 4px solid #ee2b7b;
}

.leaderboard-container .sub-text {
  margin: 0;
}


@media screen and (max-width: 800px) {
  .leaderboard-container h1 {
    font-size: 40px;
  }
  .leaderboard-container .tab {
    font-size: 16px;
  }
  .leaderboard-container .tab img {
    width: 30px;
  }
  .leaderboard-container .sub-text {
    font-size: 16px;
  }
}


@media screen and (max-width: 500px) {
  .leaderboard-container h1 {
    font-size: 30px;
  }
  .leaderboard-container .tab {
    font-size: 14px;
    padding: 10px 5px;
  }
  .leaderboard-container .tab img {
    width: 20px;
  }
}