.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  box-sizing: border-box;
}

.login-container img {
  width: 50vw;
  max-width: 400px;
  height: 37.647vw;
  max-height: 301.17px;
  object-fit: contain;
}

.login-container .tabs-container {
  width: 100%;
  max-width: 400px;
}
.login-container form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 400px;
}
.login-container input {
  display: block;
  width: 100%;
  /* max-width: 300px; */
  box-sizing: border-box;
}
.login-container p {
  text-align: center;
}
.login-container .btn {
  margin-top: 15px;
  width: 100%;
  max-width: 300px;
}
.login-container a.toggle {
  margin: 40px;
  font-size: 24px;
  text-align: center;
}

.login-container .toggle.forgot {
  color: rgb(50, 67, 95);
  margin-top: 0px;
  text-decoration: none;
}

@media screen and (min-width: 600px) {
  .login-container .btn {
    padding: 15px 20px 10px 20px;
    font-size: 24px;
    border-radius: 30px;
  }
}

@media screen and (max-width: 600px) {
  .login-container a {
    font-size: 18px;
  }
}

.login-container .tabs-container {
  display: flex;
  justify-content: space-between;
  max-width: 700px;
  margin: 0 auto;
  background: rgb(21, 23, 31);
  border-radius: 5px;
  overflow: hidden;
  border-bottom: none;
}
.login-container .tab {
  flex: 1;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  font-size: 20px;
  padding: 15px;
  cursor: pointer;
}
.login-container .tab:nth-child(1) {
  border-right: 1px solid rgba(255, 255, 255, 0.2);
}
.login-container .tab img {
  width: 40px;
}
.login-container .tab p {
  margin-bottom: -5px;
}
.login-container .tab.active {
  background: #ee2b7b;
  border-bottom: none;
}