.account-container {
  height: 100vh;
  overflow-y: auto;
}
.account-container .wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 40px 100px 40px;
  max-width: 700px;
  margin: 0 auto;
}

.account-container .btn {
 margin: 10px 0;
}