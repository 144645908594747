.splash-container {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
}

.splash-container .main {
  width: 100vw;
  min-height: calc(100vh - 105px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.splash-container .main h1 {
  text-shadow: none;
}
@media screen and (max-width: 600px) {
  .splash-container .main {
    height: calc(100vh - 85px);
  }
}

.splash-container .logo {
  width: 50vw;
  max-width: 500px;
  height: 37.647vw;
  max-height: 376.46px;
  object-fit: contain;
  margin-top: -8vw;
}

.splash-container h1 {
  font-size: 60px;
  font-weight: bold;
  color: #ee2b7b;
  text-shadow:  1px 1px 0 black,
                2px 2px 0 black,
                3px 3px 0 #750534;
  margin-top: -30px;
  margin-bottom: 60px;
  text-align: center;
  padding: 0 40px;
}

@media screen and (max-width: 1000px) {
  .splash-container h1 {
    font-size: 50px;
  }
}
@media screen and (max-width: 800px) {
  .splash-container h1 {
    font-size: 40px;
    margin-top: -20px;
  }
}
@media screen and (max-width: 600px) {
  .splash-container h1 {
    font-size: 35px;
    margin-top: -10px;
  }
}
@media screen and (max-width: 500px) {
  .splash-container h1 {
    font-size: 30px;
    margin-top: 0px;
  }
}

.splash-container .black-triangle-down {
  border-left: 50vw solid transparent;
  border-right: 50vw solid transparent;
  
  border-top: 100px solid black;
}
.splash-container .pink-triangle-down {
  border-left: 50vw solid transparent;
  border-right: 50vw solid transparent;
  
  border-top: 100px solid #ee2b7b;
}
@media screen and (max-width: 600px) {
  .splash-container .black-triangle-down {
    border-top: 50px solid black;
  }
  .splash-container .pink-triangle-down {
    border-top: 50px solid #ee2b7b;
  }
}



.splash-container .pink-background {
  background-color: #ee2b7b;
}
.splash-container h2 {
  font-size: 50px;
  /* font-weight: bold; */
  /* color: rgb(162, 182, 209); */
  color: #3f011b;
  text-align: center;
  padding: 100px 40px 100px 40px;
  text-transform: uppercase;
}


@media screen and (max-width: 800px) {
  .splash-container h2 {
    font-size: 40px;
  }
}
@media screen and (max-width: 600px) {
  .splash-container h2 {
    font-size: 35px;
  }
}
@media screen and (max-width: 500px) {
  .splash-container h2 {
    font-size: 30px;
  }
}

/* .splash-container h3 {
  font-size: 60px;
  font-weight: bold;
  color: #ee2b7b;
  background-color: rgb(18, 21, 29);
  text-align: center;
  text-transform: uppercase;
  padding: 30px 40px 10px 40px;
  margin-bottom: 40px;
} */

.splash-container p {
  font-size: 36px;
  color: rgb(162, 182, 209);
  text-align: center;
  padding: 10px 40px;
}


.splash-container .card h1 {
  margin: 40px 20px 20px 20px;
}
.splash-container .card {
  flex: 1;
  padding: 0px 0px 50px 0px;
  margin: 20px 20px 100px 20px;
  border-radius: 30px;
  /* transform: translateY(-70px); */
  box-shadow: 5px 5px 25px 0px rgba(0,0,0,0.7);
  background-color: black;
  overflow: hidden;
}

.splash-container .award {
  width: 120px;
  height: auto;
  padding: 30px;
  margin: 100px 15px 0 15px;
  border: 2px solid #ee2b7b;
  box-shadow: 0 0 15px 0 #ee2b7b;
  border-radius: 70px;
  box-sizing: border-box;
}

/* @media screen and (max-width: 1200px) {
  .splash-container .card-container {
    flex-direction: column;
  }
} */
@media screen and (max-width: 900px) {
  .splash-container .award {
    width: 80px;
    padding: 20px;
    margin: 100px 15px 0 15px;
    box-shadow: 0 0 10px 0 #ee2b7b;
  }
  .splash-container .card-container {
    flex-direction: column;
  }
  .splash-container p {
    font-size: 30px;
  }
}
@media screen and (max-width: 600px) {
  .splash-container .card {
    padding: 0px 0px 50px 0px;
    margin: 20px;
  }
  .splash-container .award {
    width: 70px;
    padding: 15px;
    margin: 100px 10px 0 10px;
  }
  .splash-container p {
    font-size: 26px;
  }
}
@media screen and (max-width: 500px) {
  .splash-container .award {
    width: 60px;
    padding: 10px;
    margin: 100px 5px 0 5px;
  }
}
@media screen and (max-width: 400px) {
  .splash-container .award {
    width: 50px;
    padding: 10px;
    margin: 100px 5px 0 5px;
    box-shadow: 0 0 5px 0 #ee2b7b;
  }
  .splash-container p {
    font-size: 20px;
  }
}

.splash-container .call-to-action {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 10px solid #ee2b7b; */
  /* background-color: rgb(46, 59, 77); */
  /* box-shadow: inset 0px 0px 10px 5px #ee2b7b; */
  background-color: #ee2b7b;
  /* background: rgb(0,0,0);
  background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(238,43,123,1) 38%); */
  /* padding-bottom: 40px; */
  /* background-color: rgb(19, 33, 46); */
  min-height: 30vh;
}

.splash-container .call-to-action .btn {
  color: white;
  transition: transform 200ms ease-in-out;
  margin: 0 20px 50px 20px;
}
.splash-container .call-to-action .btn:hover {
  background-color: black;
  transform: scale(1.05);
}