.contest-container {
  display: flex;
}

.contest-list {
  position: fixed;
  top: 0px;
  width: 220px;
  height: 100vh;
  background-color: black;
  box-sizing: border-box;
  border-right: 1px solid rgba(169, 184, 255,0.15);
  transition: transform 400ms ease-in-out;
  z-index: 100;
  overflow-y: auto;
}
.contest-list-spacer {
  flex-basis: 220px;
  flex-shrink: 0;
  transition: flex 400ms ease-in-out;
}
.contest-list.hidden {
  transform: translateX(-220px);
  box-shadow: none !important;
}
.contest-list-spacer.hidden {
  flex-basis: 0px;
}
.contest-list.mobile {
  top: 50px;
  height: calc(100vh - 50px);
  z-index: 10;
  box-shadow: 0 0 20px 10px rgba(0,0,0,1);
  padding-bottom: 80px;
}



.toggle-contest-list-btn {
  position: fixed;
  top: 75px; left: 220px;
  display: flex;
  background-color: #ee2b7b;
  padding: 10px 7px 10px 2px;
  border-radius: 0 5px 5px 0;
  transition: transform 400ms ease-in-out;
  z-index: 10;
  cursor: pointer;
}
.toggle-contest-list-btn p {
  writing-mode: vertical-rl;
  text-orientation: upright;
  user-select: none; 
  font-weight: bold;
  /* line-height: 1px; */
}
.toggle-contest-list-btn .arrow {
  width: 0; 
  height: 0; 
  position: relative;
  /* top: 50px; */
  margin-left: 2px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #520000;
  transition: transform 400ms ease-in-out;
}
.toggle-contest-list-btn .arrow.left {
  transform: rotateY(180deg);
}



.contest-list-item {
  text-align: center;
  border-bottom: 1px solid rgba(169, 184, 255,0.15);
  padding: 15px;
  transition: background-color 200ms ease-in-out;
  cursor: pointer;
}
.contest-list-item.active {
  background-color: rgb(16, 22, 36) !important;
}
.contest-list-item.active h3 {
  color: #ee2b7b;
}
.contest-list-item:hover {
  background-color: rgba(16, 22, 36, 0.6);
}
.contest-list-item.header {
  /* background-color: rgb(12, 12, 12) */
  /* border-radius: 20px; */
  /* margin-bottom: 15px; */
  /* border: 2px solid #ee2b7b; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding: 0;
  /* border-bottom-width: 5px; */
  /* border-bottom-color: #ee2b7b; */
  /* box-sizing: border-box; */
  /* box-shadow:   0px 0px 0 #ee2b7b; */
}
.contest-list-item.header h3 {
  /* text-decoration: underline; */
  font-size: 38px;
  font-weight: bolder;
  /* padding: 7px 0 7px 0; */
  color: #ee2b7b;
  /* margin: 10px 0px; */
  /* border-radius: 17px; */
  cursor: default;
  text-shadow:  1px 1px 0 black,
                2px 2px 0 #750534,
                3px 3px 0 #750534;
  margin-bottom: -5px;
  /* background-color: #750534; */
  /* border: 5px solid #750534; */
  /* padding: 10px 0; */
}
.contest-list h3 {
  font-size: 22px;
  text-transform: uppercase;
}
.contest-list p {
  font-size: 18px;
  color: rgb(120, 128, 136);
  padding-top: 5px;
}








.contest-image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
.contest-image-container .contest-image {
  display: flex;
  flex: 1;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}





.contest-details-container {
  flex: 1;
  overflow-x: hidden;
}
.contest-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
}
.contest-info h1 {
  font-size: 50px;
  text-align: center;
}
.contest-info h3 {
  font-size: 26px;
  color: rgb(120, 128, 136);
}
.contest-details-container .btn {
  margin-top: 15px;
}
.contest-description {
  font-size: 28px;
  max-width: 700px;
  padding: 0 25px;
  margin-bottom: 70px;
  white-space: pre-line;
}

@media screen and (max-width: 900px) {
  .contest-info h1 {
  font-size: 30px;
  }
  .contest-info h3 {
    font-size: 20px;
  }
  .contest-description {
    font-size: 20px;
  }
}


.contest-entry-container {
  
}



.contest-entry-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contest-rules-btn {
  position: absolute;
  top: 60px; right: 10px;
}


.contest-awards-container {
  display: flex;
  justify-content: center;
  /* margin-bottom: 40px; */
}
.contest-awards-container .award {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: #ee2b7b; */
  border: 1px solid #ee2b7b;
  box-shadow: 0 0 10px 0 #ee2b7b;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 10px;
}
.contest-awards-container img {
  width: auto;
  height: 40px;
}
.contest-awards-container p {
  font-size: 26px;
  text-align: center;
}

@media screen and (max-width: 900px) {
  .contest-awards-container .award {
    width: 75px;
    height: 75px;
  }
  .contest-awards-container img {
    height: 30px;
  }
  .contest-awards-container p {
    font-size: 20px;
  }
}

@media screen and (max-width: 600px) {
  .contest-awards-container .award {
    width: 50px;
    height: 50px;
  }
  .contest-awards-container img {
    height: 20px;
  }
  .contest-awards-container p {
    padding-top: 2px;
    font-size: 16px;
  }
}


.contest-entry-container .btn.rules {
  margin-bottom: 15px;
}



.contest-entry-container .sub-text {
  margin-bottom: 40px;
}

  
  

.entry-status {
  padding: 10px 5%;
  border-radius: 20px;
  font-size: 20px;
  margin: 20px;
  box-sizing: border-box;
  text-align: center;
}
.entry-status.approved {
  background-color: #11CF80;
  /* border: 2px solid #11CF80; */
  box-shadow: 4px 4px 0px 0 #00864e;
}
.entry-status.rejected {
  background-color: #D94A3B;
  /* border: 2px solid #D94A3B; */
  box-shadow: 4px 4px 0px 0 #97291d;
}
.entry-status.pending {
  background-color: #EDB200;
  /* border: 2px solid #EDB200; */
  box-shadow: 4px 4px 0px 0 #9c7500;
}

@media screen and (max-width: 900px) {
  .entry-status {
    font-size: 16px;
  }
}


.contest-entry-preview {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contest-entry-preview.portrait {
  max-width: calc((100vh - 50px) * (3/4));
}
.contest-entry-preview.landscape {
  height: calc((100vw - 220px) * (3/4));
  max-height: calc(100vh - 50px);
}

@media screen and (max-width: 650px) {
  .contest-entry-preview.landscape {
    height: calc(100vw * (3/4));
  }
}
.contest-entry-preview img {
  max-height: 100%;
  max-width: 100%;
}

.logo-placement-btn {
  position: absolute;
  width: 12%;
  padding: 12%;
  background-color: rgba(0,0,0,0.4);
  border: 2px dashed #ee2b7b;
  background-size: contain;
}
.logo-placement-btn:nth-child(1) {
  top: 0; left: 0;
}
.logo-placement-btn:nth-child(2) {
  top: 0; right: 0;
}
.logo-placement-btn:nth-child(3) {
  bottom: 0; left: 0;
}
.logo-placement-btn:nth-child(4) {
  bottom: 0; right: 0;
}
.logo-placement-btn .text {
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 650px) {
  .logo-placement-btn .text p {
    font-size: 16px;
  }
}

.contest-entry-container .submit-button {
  margin: 25px;
}



.rules-container {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  width: 100%;
  height: 100vh;
  overflow: auto;
  padding: 15px 15px 80px 15px;
  box-sizing: border-box;
}
.rules-container p {
  font-size: 25px;
  max-width: 700px;
  padding: 15px;
  margin: 0 auto;
  margin-bottom: 15px;
}

.deadline-text {
  background-color: #D94A3B;
  box-shadow: 3px 3px 0px 0 #97291d;
  font-size: 20px;
  font-weight: bold;
  padding: 5px 20px;
  border-radius: 20px;
  margin-bottom: 15px;
}