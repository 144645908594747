.scores-container {
  width: 100%;
  /* padding-bottom: 80px; */
  height: 100%;
  overflow-y: hidden;
  justify-self: flex-start;
}
.scores-container .wrapper {
  max-width: 700px;
  margin: 0 auto;
  overflow-y: auto;
}

.scores-container h1 {
  text-align: center;
  font-size: 60px;
  margin: 25px;
}

.my-placement-container {
  position: fixed;
  bottom: 0; left: 0; right: 0;
}
.my-placement-container .profile-row {
  max-width: 700px;
  margin: 0 auto;
  /* border-bottom: none; */
  border: 1px solid #ee2b7b;
  background-color: black;
  border-radius: 5px;
}


@media screen and (max-width: 800px) {
  .scores-container h1 {
    font-size: 40px;
  }
}
