.audition-container {
  margin: 40px;
  display: flex;
  flex-direction: column;
}

.audition-container .logo {
  width: 50vw;
  max-width: 400px;
  height: 37.647vw;
  max-height: 301.17px;
  object-fit: contain;
  margin: auto;
}

.audition-container h1 {
  font-size: 45px;
  margin-bottom: 25px;
  text-align: center;
}
.audition-container p {
  font-size: 20px;
  margin: 5px;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .audition-container h1 {
    font-size: 30px;
  }
}

.audition-container .btn.submit {
  margin: 80px 0 40px 0;
}

.eligibility-container, .audition-requirements-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin: 40px; */
}
.eligibility-container h1, .audition-requirements-container h1 {
  margin: 25px 0 15px 0;
  max-width: 700px;
  text-align: center;
}
.eligibility-container p, .audition-requirements-container p {
  /* font-size: 22px; */
  max-width: 700px;
  margin-top: 0px;
}

.eligibility-container .btn, .audition-requirements-container .btn {
  margin: 40px 0;
}

.audition-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.audition-image-container img {
  border: 1px solid #ee2b7b;
  box-shadow: 0 0 10px 0 #ee2b7b;
  margin: 40px 0 10px 0;
  border-radius: 10px;
}
.audition-image-container .portrait-selfie {
  width: 300px;
  height: 400px;
}
.audition-image-container .freestyle-selfie {
  width: 300px;
  height: 300px;
  object-fit: contain;
}





.pending-audition-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}




.rejected-audition-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rejected-audition-container .btn {
  margin: 40px 20px;
}