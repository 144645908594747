.winner-container {
  height: 100vh;
  display: flex;
  flex-direction: column; 
  /* justify-content: center; */
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 40px 25px 100px 25px;
  box-sizing: border-box;
}
.winner-container h2 {
  font-size: 45px;
  font-weight: bold;
  text-align: center;
}
.winner-container .row {
  align-items: center;
  margin: 40px;
}
.winner-container img {
  width: auto;
  height: 100px;
  margin-right: 25px;
}
.winner-container p {
  font-size: 26px;
  max-width: 600px;
  text-align: center;
  margin-top: 20px;
  color: #5a6472;
  line-height: 27px;
}
.winner-container .btn {
  margin-top: 40px;
}


@media screen and (max-width: 1150px) {
  .winner-container .stylized-display-text {
    font-size: 75px;
  }
}

@media screen and (max-width: 900px) {
  .winner-container .stylized-display-text {
    font-size: 50px;
    text-shadow:  1px 1px 0 black,
                  2px 2px 0 black,
                  3px 3px 0 #750534,
                  4px 4px 0 #750534;
  }
  .winner-container h2 {
    font-size: 35px;
  }
  .winner-container img {
    height: 75px;
  }
  .winner-container p {
    font-size: 22px;
  }
}

@media screen and (max-width: 700px) {
  .winner-container .stylized-display-text {
    font-size: 30px;
    letter-spacing: 6px;
    text-shadow:  1px 1px 0 black,
                  2px 2px 0 #750534,
                  3px 3px 0 #750534;
  }
  .winner-container h2 {
    font-size: 30px;
  }
  .winner-container img {
    height: 65px;
  }
  .winner-container p {
    font-size: 20px;
  }
}

@media screen and (max-width: 500px) {
  .winner-container .stylized-display-text {
    font-size: 20px;
    letter-spacing: 4px;
    text-shadow:  1px 1px 0 black,
                  2px 2px 0 #750534;
  }
}