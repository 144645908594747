.directions-container {
  padding: 40px;
  text-align: center;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow: auto;
  box-sizing: border-box;
}
@media screen and (max-width: 700px) {
  .directions-container {
    padding: 70px 10px;
  }
}

.directions-container h3 {
  font-size: 28px;
  max-width: 700px;
  margin: 40px auto;
}
.directions-container p {
  max-width: 700px;
  margin: 0 auto;
}

.directions-container .row > * {
  flex-basis: 300px;
  flex-grow: 0px;
  flex-shrink: 0px;
  margin: 40px 0;
}
@media screen and (max-width: 700px) {
  .directions-container .row > * {
    flex-basis: 150px;
  }
}

.directions-container .padding-left {
  padding-left: 15px;
}
.directions-container .padding-right {
  padding-right: 15px;
}
.directions-container .screenshot {
  width: 300px;
  height: 516px;
  border-radius: 10px;
  box-shadow: 0 0 10px 1px #ee2b7b;
}
@media screen and (max-width: 700px) {
  .directions-container .screenshot {
    width: 150px;
    height: 258px;
  }
}

.directions-container .lovit-icon {
  border: 1px solid #ee2b7b;
  padding: 42px 5px;
  margin: 15px 0;
  border-radius: 50%;
  width: 100px;
}
@media screen and (max-width: 700px) {
  .directions-container .lovit-icon {
    padding: 21px 2.5px;
    width: 50px;
  }
}



.directions-container .awards-container > div {
  margin: 0 10px;
}
.directions-container .awards-container p {
  font-size: 16px;
}

@media screen and (max-width: 800px) {
  .directions-container .awards-container > div {
    margin: 0 5px;
  }
  .directions-container .awards-container p {
    font-size: 12px;
  }
}
@media screen and (max-width: 500px) {
  .directions-container .awards-container > div {
    margin: 0 0px;
  }
}


.pinnie-of-the-year {
  width: 90vw;
  max-width: 700px;
}